<template>
    <div>
        <AdminTop
            :heading="reminderId ? 'Redigera kom ihåg' : 'Skapa kom ihåg'"
        />

        <BaseForm
            v-if="reminder"
            :loading="loading"
            :submit-label="reminderId ? 'Uppdatera' : 'Lägg till'"
            @submit="handleSubmit"
        >
            <FormGroup
                label="Titel"
                name="title"
            >
                <InputField
                    v-model="reminder.title"
                    name="title"
                    type="text"
                    required
                />
            </FormGroup>

            <FormGroup
                label="Länk"
                name="url"
            >
                <InputField
                    v-model="reminder.url"
                    name="url"
                    type="url"
                />
            </FormGroup>

            <FormGroup
                label="Datum"
                name="date"
            >
                <DatePicker v-model="reminder.date" />
            </FormGroup>

            <FormGroup
                label="Elever"
                name="students"
            >
                <MultipleSelectField
                    v-if="users"
                    v-model="reminder.users"
                    :items="users"
                />
            </FormGroup>
        </BaseForm>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseForm from '@/components/form/BaseForm';
import FormGroup from '@/components/form/FormGroup';
import InputField from '@/components/form/InputField';
import DatePicker from '@/components/form/DatePicker';
import MultipleSelectField from '@/components/form/MultipleSelectField';
import ReminderService from '@/services/admin/ReminderService';
import UserService from '@/services/admin/UserService';

export default {
    components: {
        AdminTop,
        BaseForm,
        FormGroup,
        DatePicker,
        MultipleSelectField,
        InputField
    },

    data: () => ({
        loading: false,
        reminder: null,
        users: null
    }),

    computed: {
        reminderId () {
            return this.$route.params.id;
        }
    },

    async created () {
        const [reminder, users] = await Promise.all([
            this.getReminder(),
            UserService.students()
        ]);

        this.users = users.data;

        if (!this.reminderId) {
            reminder.users = this.users.map(user => user.id);
        }

        this.reminder = reminder;
    },

    methods: {
        async getReminder () {
            if (!this.reminderId) {
                return {
                    title: null,
                    date: null,
                    url: null,
                    users: []
                };
            }

            const res = await ReminderService.get(this.$route.params.id);

            const reminder = res.data;

            reminder.users = reminder.users.map(user => user.id);

            return reminder;
        },

        async handleSubmit () {
            this.loading = true;

            try {
                if (this.reminderId) {
                    await this.update();
                } else {
                    await this.create();
                }
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }

            this.loading = false;
        },

        async create () {
            const res = await ReminderService.create(this.reminder);

            if (res.status === 200 && res?.data?.reminder?.id) {
                this.$router.push(`/admin/reminders/${res.data.reminder.id}`);
            }
        },

        async update () {
            return await ReminderService.update(this.reminderId, this.reminder);
        }
    }
};
</script>
